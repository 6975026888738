<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Recuperar Clave</h1>
                  <hr />
                  <h2>Ingrese su nueva clave y confírmela</h2>
                  <hr />
                  <p class="text-muted"></p>

                  <CInput
                    v-model="clave"
                    placeholder="Nuevo Password"
                    type="password"
                    autocomplete="curent-password"
                    required
                    valid-feedback="Requisitos mínimos de password cumplidos"
                    invalid-feedback="Requisitos mínimos de password insuficientes.  Debe contener al menos doce (12) caracteres y debe combinar caracteres de distinto tipo (mayúsculas, minúsculas, números y símbolos)"
                    :is-valid="passwordValidator"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>

                  <CInput
                    v-model="confirmacion_clave"
                    placeholder="Confirmación nuevo Password"
                    type="password"
                    autocomplete="curent-password"
                    required
                    valid-feedback="Requisitos mínimos de password cumplidos"
                    invalid-feedback="Requisitos mínimos de password insuficientes.  Debe contener al menos doce (12) caracteres y debe combinar caracteres de distinto tipo (mayúsculas, minúsculas, números y símbolos)"
                    :is-valid="passwordValidator"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <span
                    style="
                      width: 100%;
                      margin-top: 0.25rem;
                      font-size: 80%;
                      color: #e55353;
                    "
                    v-if="
                      confirmacion_clave != '' &&
                      clave != '' &&
                      confirmacion_clave != clave
                    "
                    >La confirmación del password no corresopnde.</span
                  >

                  <CRow>
                    <v-alert v-if="mensaje" dense text type="success">
                      {{ mensaje }}
                    </v-alert>
                    <v-alert v-if="error" dense outlined type="error">
                      Se presentó un error.
                    </v-alert>

                    <CCol col="6" class="text-left">
                      <div class="text-center" v-if="loading">
                        <div class="spinner-border" role="status"></div>
                      </div>
                      <CButton
                        v-if="!loading && !mensaje"
                        color="primary"
                        class="px-4"
                        @click="asignarClave()"
                        :disabled="!validarFormulario(correo_electronico)"
                        >Establecer nueva clave</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right"> </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <img src="key.png" class="c-avatar-img" />
                <p></p>
                <h3 class="secondary-color">Master Suite</h3>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import UserService from "@/services/user.service";

import validationField from "@/util/validationField";

export default {
  name: "asignar-clave",
  components: {},
  mounted() {
    this.correo_electronico = this.$route.params.correo_electronico;
    this.token_verificacion = this.$route.params.token_verificacion;
    // console.log(this)
  },
  data() {
    return {
      loading: false,
      mensaje: "",
      error: false,
      cambio_clave: false,
      token_verificacion: null,
      correo_electronico: "",
      clave: "",
      confirmacion_clave: "",
      mensaje_error:
        "Se ha presentado un error en el sistema. Por favor intente más tarde. Si el error continua por favor comuníquese con el administrador del sistema.",
      mensaje_success:
        "Clave asignada satisfactoriamente. Ya puede iniciar sesión.",
    };
  },
  methods: {
    passwordValidator(val) {
      return val === undefined
        ? null
        : val
        ? validationField("password", val)
        : false;
    },
    validarFormulario() {
      let valido = true;

      if (
        !(this.passwordValidator(this.clave) && 
        this.confirmacion_clave == this.clave)
      ) {
        valido = false;
      }
      return valido;
    },
    validarClave() {
      let valido = true;

      if (!this.clave || !validationField("password", this.clave)) {
        valido = false;
      }
      return valido;
    },
    validarConfirmacionClave() {
      let valido = true;

      if (!this.confirmacion_clave || this.clave !== this.confirmacion_clave) {
        valido = false;
      }
      return valido;
    },
    asignarClave() {
      this.cambio_clave = false;
      this.loading = true;
      this.error = false;

      let asignar_clave = {
        remember_token: this.token_verificacion,
        email: this.correo_electronico,
        password: this.clave,
        confirmacion_clave: this.confirmacion_clave,
      };

      UserService.asignarClave(asignar_clave).then(
        (response) => {
          if (response.status === "success") {
            this.cambio_clave = true;
            this.procesando = false;
            this.error = false;
          } else {
            this.cambio_clave = false;
            this.procesando = false;
            this.error = true;
          }
          this.envio_correo = true;
          this.loading = false;
          this.error = false;
          this.mensaje = response.data.data;
          console.log(response.data);
        },
        (error) => {
          this.error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(this.error);
          this.loading = false;
        }
      );
      /*
      UserService.asignarClave(asignar_clave).then((response) => {
        if (response.status === "success") {
          this.cambio_clave = true;
          this.procesando = false;
          this.error = false;
        } else {
          this.cambio_clave = false;
          this.procesando = false;
          this.error = true;
          // console.log(response)
        }
      });
      */
    },
    abrirModalInicioSesion() {
      this.Inicio();
      this.$parent.$parent.$children[0].$children[1].abrirModalInicioSesion();
    },
    Inicio() {
      router.push({
        name: "home",
      });
    },
  },
};
</script>

<style lang="scss">
footer {
  margin-left: 0 !important;
}
</style>